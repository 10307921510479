<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
     <template #category="{ row }">
        <div>
          <p>
            {{ row.category }}
            <span :style="{color:typeMap[row.type].color || ''}">{{typeMap[row.type].name}}</span>
          </p>
        </div>
      </template>
      <template #name="{ row }">
        <div>
          <p>{{ row.name }}</p>
          <p>{{ row.phone }}</p>
        </div>
      </template>
      <template #addr="{ row }">
        <div>
          <p>{{ row.mechanism_name }}</p>
          <p>{{ row.address }}</p>
        </div>
      </template>
      <template #status="{ row }">
        <div>
          <el-tag :hit="false" size="small" effect="plain" :type="statusMap[row.status].tag">{{statusMap[row.status].name}}</el-tag>
        </div>
      </template>
      <template #handler="{ row }">
        <div>
          <el-button type="primary" size="small" @click="claim(row)"
            >认领任务</el-button
          >
        </div>
      </template>
    </auto-table>
  </div>
</template>

<script>
import {statusMap,typeMap} from './enum.js'
export default {
  data() {
    return {
      DataList: [],
      Option: [
        { name: "课程类目", value: "category" ,type: "custom"},
        { name: "联系人", value: "name" ,type: "custom"},
        { name: "课节数", value: "course_num" },
        { name: "城市", value: "city" },
        { name: "地址", value: "addr", type: "custom" },
        { name: "状态", value: "status", type: "custom" },
        { name: "添加时间", value: "create_time" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      finished: false,
       managerInfo: JSON.parse(localStorage.getItem("managerInfo")),
       statusMap:statusMap,
       typeMap:typeMap
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    claim(row){
      this.$router.push({name:'applyFlowDetail',query:{id:row.id}})
    },
     mytask(){
      this.$get('/user/disApplyFlow/queryManagerListPage',{admin_id:this.managerInfo.id})
    },
    toDetails(row){
        this.$router.push({name:'needTask',query:{id:row.id}})
    },
    addActivity() {
      this.$router.push({ name: "addActivity" });
    },
    updateInfo(row) {
      this.$router.push({ name: "addActivity", query: { aid: row.id } });
    },
    del(row){
      this.$confirm("确定删除").then(()=>{
         this.$post('/user/businessActivityType/update',{id:row.id,status:3}).then(res=>{
          this.getList(1);
        })
      })
    },
    updateStatus(row,status){
        if(status == 2){
          if(new Date(row.end_time).getTime() < Date.now()){
            this.$message("活动已结束，不能上架")
            return
          }
        }

        this.$post('/user/userCourseNeed/update',{id:row.id,status}).then(res=>{
          this.getList(1);
        })
    },
    //获取列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/discountApply/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          status:0
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.type-dis{
  color: #53a3ee;
}
.type-spl{
 color: #f3a040;
}
</style>