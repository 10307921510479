var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('el-form',{attrs:{"inline":true}},[_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.getList(1)}}},[_vm._v("搜索")])],1)],1),_c('auto-table',{attrs:{"DataList":_vm.DataList,"option":_vm.Option,"total":_vm.Total},on:{"changePage":_vm.getList},scopedSlots:_vm._u([{key:"category",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('p',[_vm._v(" "+_vm._s(row.category)+" "),_c('span',{style:({color:_vm.typeMap[row.type].color || ''})},[_vm._v(_vm._s(_vm.typeMap[row.type].name))])])])]}},{key:"name",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('p',[_vm._v(_vm._s(row.name))]),_c('p',[_vm._v(_vm._s(row.phone))])])]}},{key:"addr",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('p',[_vm._v(_vm._s(row.mechanism_name))]),_c('p',[_vm._v(_vm._s(row.address))])])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('el-tag',{attrs:{"hit":false,"size":"small","effect":"plain","type":_vm.statusMap[row.status].tag}},[_vm._v(_vm._s(_vm.statusMap[row.status].name))])],1)]}},{key:"handler",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.claim(row)}}},[_vm._v("认领任务")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }